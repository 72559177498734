
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class role extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  table1_all_data=[]
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
table1_total= 0;
table1_search_data = {
  role: ''

}

rule_table1_search_data = {

}

table1_add_dialog = {
  open: false,
  title: '新增'
}

table1_add_data = {
  role: '',
  dept_id: ''

}

rule_table1_add_data = {

}

table1_del_dialog = {
  open: false,
  title: '删除'
}

table1_del_data = {
  id: 0

}

table1_edit_dialog = {
  open: false,
  title: '编辑'
}

table1_edit_data = {
  id: 0,
  role: '',
  dept_id: ''

}

rule_table1_edit_data = {

}

table1_edit1_dialog = {
  open: false,
  title: '权限'
}

table1_edit1_data = {
  id: 0,
  access: []

}

rule_table1_edit1_data = {

}

depts:any[]=[]
dataSource:any[]=[]
dept_id_51 = new Map<any, string>([])

table1_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    role: this.table1_search_data.role

  }
  Api.http_roletable10(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.table1_all_data = res.data.data
      this.table1_total = res.data.total
    }
  })
}
table1_add_init(row) {
  this.get_depts(row.id)
  this.table1_add_dialog.open = true;

}
table1_add_cancle() {
  this.table1_add_dialog.open = false;
}
table1_add_ok() {
  this.$refs.ref_table1_add_data.validate(valid => {
    if (valid) {
      const postData = {
        role: this.table1_add_data.role,
        dept_id: this.table1_add_data.dept_id

      }
      Api.http_roletable11(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.table1_add_dialog.open = false
          this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
table1_del_init(row) {
  this.table1_del_data.id = row.id;
  this.table1_del_dialog.open = true;

}
table1_del_cancle() {
  this.table1_del_dialog.open = false;
}
table1_del_ok() {
  const postData = {
    id: this.table1_del_data.id

  }
  Api.http_roletable12(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.table1_del_dialog.open = false
      this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
      this.$message.success(JSON.stringify(res.message))
    }
  })
}
table1_edit_init(row) {
  this.get_depts(row.id)

  Api.http_roletable1get5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.table1_edit_data.id = row.id;
      this.table1_edit_data.role = res.data.role;
      this.table1_edit_data.dept_id = res.data.dept_id;
      this.table1_edit_dialog.open = true;
    }
  })

}
table1_edit_cancle() {
  this.table1_edit_dialog.open = false;
}
table1_edit_ok() {
  this.$refs.ref_table1_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.table1_edit_data.id,
        role: this.table1_edit_data.role,
        dept_id: this.table1_edit_data.dept_id

      }
      Api.http_roletable15(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.table1_edit_dialog.open = false
          this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
table1_edit1_init(row) {
  this.get_dataSource(row.id)

}
table1_edit1_cancle() {
  this.table1_edit1_dialog.open = false;
}
table1_edit1_ok() {
  this.$refs.ref_table1_edit1_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.table1_edit1_data.id,
        access: this.table1_edit1_data.access

      }
      Api.http_roletable16(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.table1_edit1_dialog.open = false
          this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
table1_init() {
  this.table1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize);

}
get_depts(id = -1) {
  Api.http_roletable1depts0({ id: id, offset: 0, length: 999 }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.depts = res.data.data

    }
  })
}
get_dataSource(id = -1) {
  Api.http_roletable1dataSource0({ id: id, offset: 0, length: 999 }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.dataSource = res.data.data
      Api.http_roletable1get6({ id: id }).then(res1 => {
        if (res1.code !== '200') {
          this.$message.error(res1.message)
        } else {
          this.table1_edit1_data.id = id;
          this.table1_edit1_data.access = res1.data.access;
          this.table1_edit1_dialog.open = true;
        }
      })

    }
  })
}
created() {
  this.table1_init();

}

}
